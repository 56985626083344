import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {
        user_id: 0,
        username: '',
        eth: 0,
        usdt: 0,
        btc: 0,
        total_money: 0,
    },
    config: {
        pay_btc_addr: '',
        pay_erc_addr: '',
        pay_trx_addr: '',

        pay_btc_img: '',
        pay_trx_img: '',
        pay_erc_img: '',

        price_btc: 44123,
        price_erc: 1,
        price_trx: 1,

        cs_url: '',
        alias: {},
    },
    assets: [],
    symbol_list: [],
    tickers: {},
    tickers_percent: {},

    snackbar_color: 'primary',
    snackbar_show: false,
    snackbar_message: '',
  }),
  actions: {
    symbolAlias(symbol) {
      return this.config.alias[symbol] || symbol
    },
    setTickers(key, value) {
      this.tickers[key] = value
    },
    getTickers(key, defaultValue){
      return this.tickers[key] || defaultValue;
    },
    setTickersPercent(key, value) {
      this.tickers_percent[key] = value
    },
    getTickersPercent(key, defaultValue){
      return this.tickers_percent[key] || defaultValue;
    },
    getUser() {
        return this.user;
    },
    setUser(data) {
      console.log("data", data)
        var userinfo = data.userinfo;
        var config = data.config;
        var assets = data.assets_list;
        var symbol_list = data.symbol_list;

        this.setConfig(config);
        this.setSymbolList(symbol_list);
        this.setAsset(assets);

        localStorage.setItem('userdata', JSON.stringify(userinfo));
        localStorage.setItem('userid', userinfo.user_id);
        localStorage.setItem('user', JSON.stringify(userinfo));
        this.user = userinfo
    },
    setConfig(config) {
      this.config = config
    },
    setSymbolList(config) {
      this.symbol_list = config
    },
    setAsset(assets) {
      this.assets = assets
    },
    message(message){
      this.snackbar_show = true;
      this.snackbar_message = message;
    },
    success_message(message){
      this.snackbar_show = true;
      this.snackbar_message = message;
      this.snackbar_color = 'success';
    },
    error_message(message){
      this.snackbar_show = true;
      this.snackbar_message = "Error: " + message;
      this.snackbar_color = 'error';
    },
    close_message(){
      this.snackbar_show = false;
    }
  },
});